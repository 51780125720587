<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      cancel-title="Đóng lại"
      centered
      :title="'Hồi đáp'"
      @ok="$emit('reply', content)"
    >
      <b-form>
        <b-form-group label-for="basicInput">
          <label for="textarea-default">Hồi đáp</label>
          <b-form-textarea
            id="textarea-default"
            v-model="content"
            rows="3"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      content: '',
    }
  },
}
</script>
<style lang="">

</style>
