<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      cancel-title="Đóng lại"
      centered
      :title="title"
      @ok="$emit('reply', dataDetail.feedbackReply)"
    >
      <b-form>
        <b-form-group>
          <label>Họ tên</label>
          <b-form-input
            v-model="dataDetail.name"
            disabled
          />
        </b-form-group>
        <b-form-group>
          <label>Email</label>
          <b-form-input
            v-model="dataDetail.email"
            disabled
          />
        </b-form-group>
        <b-form-group v-if="dataDetail.phoneNumber">
          <label>Số điện thoại</label>
          <b-form-input
            v-model="dataDetail.phoneNumber"
            disabled
          />
        </b-form-group>
        <b-form-group>
          <label>Tiêu đề</label>
          <b-form-input
            v-model="dataDetail.subject"
            disabled
          />
        </b-form-group>
        <!-- mô tả  -->
        <b-form-group label-for="basicInput">
          <label for="textarea-default">Nội dung góp ý</label>
          <b-form-textarea
            id="textarea-default"
            v-model="dataDetail.content"
            rows="3"
            no-resize
            disabled
          />
        </b-form-group>
        <b-form-group label-for="basicInput">
          <label for="textarea-default">Hồi đáp</label>
          <b-form-textarea
            id="textarea-default"
            v-model="dataDetail.feedbackReply"
            rows="3"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
}
</script>
<style lang="">

</style>
