<template>
  <div
    id="users"
    class="page-container-table"
  >
    <button-all-header
      :arrayExcel="[]"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideExportFile="false"
      :hideDowload="false"
      :hideImportFile="false"
      :hideAdd="false"
      @clickDelete="deleteItems"
      @clickDowloadSample="() => {}"
      @clickExportExcel="() => {}"
      @importFile="() => {}"
      @clickAdd="() => {}"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >

      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:chủ đề đề -->
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <span :id="'id_' + props.row.id">{{ props.row.name }}</span>
          <b-popover
            :target="'id_' + props.row.id"
            triggers="hover"
            placement="top"
          >
            <template #title>{{ props.row.name }}</template>
            <span>
              <feather-icon
                :icon="'EmailIcon'"
                size="16"
                class="mr-50"
              />
              <span>{{ props.row.email }}</span>
            </span>
            <span v-if="props.row.phone">
              <feather-icon
                :icon="'PhoneIcon'"
                size="16"
                class="mr-50"
              />
              <span>{{ props.row.phone }}</span>
            </span>
          </b-popover>
        </span>

        <!-- Column: người tạo-->
        <span v-else-if="props.column.field === 'Email'">
          <span class="text-nowrap">{{ props.row.email }}</span>
        </span>

        <!-- Column: ngày tạo-->
        <span v-else-if="props.column.field === 'topic'">
          <span>{{ props.row.subject }}</span>
        </span>

        <!-- Column: người sửa -->
        <span v-else-if="props.column.field === 'dateCreated'">
          <span class="text-nowrap">{{ props.row.created | formatDateToDDMM }}</span>
        </span>
        <!-- Column:ngày sửa -->
        <span v-else-if="props.column.field === 'visible'">
          <feather-icon
            :icon="props.row.show ? 'CheckIcon' : 'XIcon'"
            size="18"
            class="text-body"
          />
        </span>
        <span v-else-if="props.column.field == 'feedbackStatusString'">
          <div v-if="props.row.feedbackStatus == 'NotReply'">
            <b-badge variant="light-warning">{{ props.row.feedbackStatusString }}</b-badge>
          </div>
          <div v-else>
            <b-badge variant="light-success">{{ props.row.feedbackStatusString }}</b-badge>
          </div>
        </span>
        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">
          <span
            @click="showModalEdit(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xem thông tin, phản hồi nội dung'"
              icon="MessageSquareIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span>
            <b-dropdown
              variant="link"
              boundary="viewport"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body"
                />
              </template>
              <b-dropdown-item @click="setVisibleItem(props.row)">
                <feather-icon
                  :icon="props.row.show ? 'EyeOffIcon' : 'EyeIcon'"
                  size="16"
                  class="mr-50"
                />
                <span>{{ props.row.show === false ? 'Hiện góp ý' : 'Ẩn góp ý' }}</span>
              </b-dropdown-item>
              <!-- xem bao cao  -->
              <b-dropdown-item
                @click="deleteItem(props.row.id)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="18"
                  class="text-body mr-50"
                  title="Xóa"
                />
                <span>Xóa bỏ</span>
                <!-- <span>{{ $t("common.action-table.see-report") }}</span> -->
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- thêm mới modal  -->
    <modal-create
      :id="modalIdEdit"
      :dataDetail="dataDetail"
      title="Hồi đáp góp ý"
      @reply="reply"
    />

  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
  BPopover,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreate from './components/ModalCreate.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalReply from './components/ModalReply.vue'

import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constanst/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    BPopover,
    VBTooltip,
    ModalCreate,
    ModalReply,
    ButtonAllHeader,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'HỌ TÊN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'CHỦ ĐỀ',
          field: 'topic',
          sortable: false,
        },
        {
          label: 'NGÀY TẠO',
          field: 'dateCreated',
          sortable: false,
        },
        {
          label: 'HIỂN THỊ',
          field: 'visible',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'feedbackStatusString',
          sortable: false,
        },

        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          width: '175px',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      userId: '',
      modalIdEdit: 'modalIdEdit',
      modalReplyId: 'modalReplyId',
      totalRecord: 0,
      dataList: [],
      idDetail: '',
      dataDetail: {},
    }
  },

  created() {
    this.fetchData(this.urlQuery)
  },

  methods: {
    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click show modal thêm vs xóa
    async showModalEdit(val) {
      this.idDetail = val
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_SUGGESTION_BOX}${this.idDetail}`)
      this.dataDetail = data
      this.$bvModal.show(this.modalIdEdit)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_SUGGESTION_BOX, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    updateStatus(id) {
      this.idDetail = id
      this.$bvModal.show(this.modalReplyId)
    },
    // Cập nhật trạng thái
    async reply(content) {
      this.$showAllPageLoading()
      await axiosApiInstance.put(ConstantsApi.UPDATA_SUGGESTION_BOX, { id: this.idDetail, feedbackReply: content }).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Chuyển trạng thái thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Chuyển trạng thái không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Ẩn/hiện góp ý trên trang chủ
    async setVisibleItem(item) {
      this.$showAllPageLoading()
      await axiosApiInstance.put(ConstantsApi.VISIBLE_SUGGESTION_BOX, { ids: [item.id], show: !item.show }).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Cập nhật ẩn/hiện thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Cập nhật ẩn/hiện không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_SUGGESTION_BOX, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
